import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/manifest'

class VoyageManifestService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    show(id, operatedByOfficeId = 0, bookedOfficeId = null) {
        let url = `${URL}/${id}/get/0/${operatedByOfficeId ? operatedByOfficeId : 0}`;
        if(bookedOfficeId){
            url += `?booked=${bookedOfficeId}`
        }
        return http.get(url)
    }
    updateOpenClosed(id, data) {
        return http.post(`${this.getUrl()}/${id}/update-status`, data)
    }
    sendStatusRequest(id, data) {
        return http.put(`/api/bill-of-lading/${id}/update-approval`, data)
    }
}

export default new VoyageManifestService()